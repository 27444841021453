@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

.arabic-font {
  font-family: "Cairo";
}

.footer {
  background-color: #fddccd;
  padding: 20px;
  width: 100%;
}

.footer a {
  text-decoration: none;
  color: none;
}

.sign-up-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.centered {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 20px;
  margin-top: 40px;
}

.sign-up-text {
  color: #231f20;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.row-button {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.sign-up-input {
  border-radius: 10px;
  border: 1px solid #000;
  padding: 10px 20px;
  background-color: transparent;
  color: #000;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  height: 56px;
  outline: none;
  width: 85%;
}
.sign-up-input::placeholder {
  color: #000;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.submit-button {
  border-radius: 10px;
  border: 1px solid #000;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  color: #020202;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  height: 56px;
  width: 15%;
}
.name-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 50px;
  margin-top: 40px;
  margin-bottom: 50px;
  width: 100%;
}
.name-footer {
  color: #231f20;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
}
.icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}
.rights {
  display: flex;
  width: 100%;
  padding: 0px 50px;
  color: #231f20;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: -0.32px;
}
.mob {
  display: none;
}

@media screen and (max-width: 767px) {
  .web {
    display: none;
  }
  .mob {
    display: block;
  }
  .footer {
    background-color: #fddccd;
    padding: 20px 20px;
    width: 100%;
  }
  .name-column {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .name {
    color: #818181ab;
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.32px;
  }

  .name-copy {
    color: #818181ab;

    font-family: "Work Sans";
    font-size: 10px;
    font-weight: 300;
    line-height: 7px;
    letter-spacing: -0.3199999928474426px;
  }
  .line {
    height: 1px;
    width: 100%;
    background-color: #818181ab;
  }
  .center {
    text-align: center;
    color: #020202;
    font-family: "Work Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.32px;
    margin-bottom: -10px;
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 0px;
  }
}
