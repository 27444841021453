.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff8f0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1101;
  height: fit-content;
  height: 60vh;
  padding: 30px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-title {
  font-family: "DM Serif Display", serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: -0.32px;
  color: #e2a488;
  position: relative;
  margin-bottom: 30px;
  text-align: center;
}

.popup-subtitle {
  font-family: "Work Sans", sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 42px;
  color: #5a5a5a;
  text-align: center;
  margin-bottom: 20px;
}
.popup-subtitle-secondary {
  font-family: "Work Sans", sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 42px;
  color: #5a5a5a;
  text-align: center;
  margin-bottom: 30px;
}

.popup-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.popup-button {
  border-radius: 10px;
  background-color: #fddccd;
  height: 56px;
  width: 50%;
  color: #020202;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-button:hover {
  background-color: #e4c6b9;
}

.popup-close-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.popup-close-button img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.popup-close-button-white {
  display: none;
}

@media screen and (max-width: 767px) {
  .popup-container {
    top: 50%;
    left: 50%;
    flex-direction: column;
    width: 290px;
    height: 330px;
    padding: 15px;
  }

  .popup-close-button {
    display: none;
  }

  .popup-title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.32px;
    text-align: center;
    margin-bottom: 20px;
  }

  .popup-title::before {
    left: -30px;
    font-size: 24px;
  }

  .popup-subtitle {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    text-align: center;
    margin-bottom: 10px;
  }

  .popup-subtitle-secondary {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    text-align: left;
    margin-bottom: 20px;
  }

  .popup-button {
    height: 48px;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
  }

  .popup-close-button-white {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .popup-close-button-white img {
    width: 30px;
    height: 30px;
  }
}
